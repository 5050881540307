@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "components/global/TopBar/slick";


@font-face {
	font-family: 'Helvetica';
	src: local('Helvetica'), url("assets/fonts/Helvetica.otf") format('otf');
}
html, body, #root {
	left: 0;
	top: 0;
	//right: 0;
	//bottom: 0;
	margin: 0;
	//height: 100%;
	font-family: 'Roboto', 'DM San', serif;
}
*, *:after, *:before {
	box-sizing: border-box;
}
* button{
	cursor: pointer;
	font-family: 'Roboto', 'DM San', serif;
}
* button:active, button:focus {
	outline: none;
}
* button::-moz-focus-inner {
	border: 0;
}
* input {
	min-width: 20px;
}
.MuiPaper-rounded {
	border-radius: 16px !important;
}
.MuiDialog-paper {
	margin: 0 !important;
}

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
	display: none !important;
}
.gm-bundled-control .gmnoprint {
	display: block;
}
.gmnoprint:not(.gm-bundled-control) {
	display: none;
}
