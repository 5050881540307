@import "../../../../../src/assets/scss/_variables.scss";

.customStyles {
	.carousel .slider-wrapper {
		max-width: 416px;
	}
	.carousel .control-dots {
		position: relative;
		margin-top: 5px;
	}
	.carousel .control-dots .dot {
		width: 6px;
		height: 6px;
		box-shadow: none;
		background: linear-gradient(0deg, rgba(43, 43, 43, 0.1), rgba(43, 43, 43, 0.1)), #F3F3F3;
	}
	.carousel .control-dots .dot.selected {
		width: 20px;
		background: $red;
		border-radius: 4px;
	}
	.carousel .slide {
		background: white;
	}
}
