@import '../../../src/assets/scss/_variables.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	background: $gray-background;
}

.main {
	flex-grow: 1;
	@media screen and (max-width: 650px) {
		overflow: hidden;
	}
}
